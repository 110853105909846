import { graphql, PageProps } from 'gatsby';
import ContentPage from '~/components/Content/Page';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';

const ActivitiesLandingPage = ({ data }: PageProps<Queries.ActivityLandingPageQuery>) => {
  return (
    // @ts-ignore
    <Layout navColor={data.mainPage?.navColor}>
      <Metadata />
      {/* @ts-ignore */}
      <ContentPage page={data.mainPage} />
    </Layout>
  );
};

export default ActivitiesLandingPage;

export const pageQuery = graphql`
  query ActivityLandingPage {
    pages: allContentfulPage(filter: { type: { eq: "activity" } }) {
      nodes {
        title
        slug
      }
    }
    mainPage: contentfulPage(slug: { eq: "activities" }) {
      ...ContentPageFragment
    }
  }
`;
